// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-preview-jsx": () => import("/opt/build/repo/src/components/Preview.jsx" /* webpackChunkName: "component---src-components-preview-jsx" */),
  "component---src-components-edit-edit-jsx": () => import("/opt/build/repo/src/components/Edit/Edit.jsx" /* webpackChunkName: "component---src-components-edit-edit-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

